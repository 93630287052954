import Home from "../views/Home";
import Playback from "../views/Playback";
import AudioSelector from "../views/AudioSelector";

const routes = [
    {
        path: "/",
        element: <Home />,
        exact: true,
    },
    {
        path: "/playback",
        element: <Playback />,
        exact: true,
    },
    {
        path: "/audioselector",
        element: <AudioSelector />,
        exact: true,
    },
];

export default routes;