import './App.css';
import { Routes, Route, HashRouter } from 'react-router-dom';
import routes from './routes/Routes';
import PageContextProvider from './context/PageContext';

function App() {
  return (
    <PageContextProvider>
      <HashRouter>
        <Routes>
          {routes.map(({ path, element, exact }) => (
            <Route key={path} path={path} exact={exact} element={element} />
          ))}
        </Routes>
      </HashRouter>
    </PageContextProvider>
  );
}

export default App;
