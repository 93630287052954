const assets = {
    1: {
        "hu": {
            "title": "Teleki visszaemlékezései Petőfire",
            "description": "",
            "sound": "",
            "subpoints": [
                {
                    "title": "Petőfi és Teleki első találkozása",
                    "sound": "./sounds/Teleki_Visszaemlékezés_Petőfire_1.mp3"
                },
                {
                    "title": "Az Erdélyben című költemény születése",
                    "sound": "./sounds/Teleki_Visszaemlékezés_Petőfire_2.mp3"
                },
                {
                    "title": "Petőfi kölcsönkéri a kastélyt a mézeshetek idejére",
                    "sound": "./sounds/Teleki_Visszaemlékezés_Petőfire_3.mp3"
                }
            ]
        },
        "ro": {
            "title": "Amintirile lui Teleki despre Petőfi",
            "description": "",
            "sound": "",
            "subpoints": [
                {
                    "title": "Prima întâlnire între Petőfi și Teleki",
                    "sound": "./sounds/Teleki_Amintiri_despre_Petőfi_Teleki_Coltau_1.mp3"
                },
                {
                    "title": "Nașterea poeziei „În Transilvania”",
                    "sound": "./sounds/Teleki_Amintiri_despre_Petőfi_Teleki_Coltau_2.mp3"
                },
                {
                    "title": "Petőfi împrumută castelul pentru perioada lunii de miere",
                    "sound": "./sounds/Teleki_Amintiri_despre_Petőfi_Teleki_Coltau_3.mp3"
                }
            ]
        }
    }
}


export default assets;