import { createContext, useState } from "react";

export const PageContext = createContext({
    isHomepage: false,
    setIsHomepage: () => { },
});

const PageContextProvider = (props) => {
    const [isHomepageLoaded, setIsHomepageLoaded] = useState(false);

    const setHomepageLoaded = () => {
        setIsHomepageLoaded(true);
    }

    return (
        <PageContext.Provider value={{ isHomepage: isHomepageLoaded, setIsHomepage: setHomepageLoaded }}>
            {props.children}
        </PageContext.Provider>
    )
}

export default PageContextProvider;