import React, { useRef, useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import playIcon from "../assets/icons/play-icon.svg";
import pauseIcon from "../assets/icons/pause-icon.svg";
import navIcon from "../assets/icons/back-button.svg";
import { PageContext } from "../context/PageContext";

const Playback = () => {
  const audioRef = useRef(null);
  const location = useLocation();
  const assets = location.state;
  const navigate = useNavigate();
  const ctx = useContext(PageContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currTime, setCurrTime] = useState({ min: "", sec: "" });
  const [seconds, setSeconds] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!ctx.isHomepage) {
      navigate("/");
    }
  }, [ctx.isHomepage, navigate]);

  const startSound = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const pauseSound = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleRangeChange = (e) => {
    if (audioRef.current) {
      audioRef.current.currentTime = parseFloat(e.target.value);
      setSeconds(parseFloat(e.target.value));
    }
  };

  const navigateToHomepage = () => {
    if (audioRef.current) {
      pauseSound();
    }
    if (assets.path === "/audioselector") {
      navigate("/audioselector", { state: assets.allassets });
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    const updateCurrentTime = () => {
      if (audioRef.current) {
        setSeconds(audioRef.current.currentTime);
        const min = Math.floor(audioRef.current.currentTime / 60);
        const sec = Math.floor(audioRef.current.currentTime % 60);
        setCurrTime({ min, sec });
      }
    };

    const updateDuration = () => {
      if (audioRef.current) {
        setDuration(audioRef.current.duration);
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", updateCurrentTime);
      audioRef.current.addEventListener("durationchange", updateDuration);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", updateCurrentTime);
        audioRef.current.removeEventListener("durationchange", updateDuration);
      }
    };
  }, []);

  useEffect(() => {
    const handleSoundEnd = () => {
      if (audioRef.current) {
        setIsPlaying(false);
        setSeconds(0);
        setCurrTime({ min: 0, sec: 0 });
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener("ended", handleSoundEnd);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("ended", handleSoundEnd);
      }
    };
  }, []);

  useEffect(() => {
    const handleHashChange = (e) => {
      pauseSound();
    };
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <>
      {assets ? (
        <div className="playback-view">
          <div className="titles-holder">
            <h1 className="title">{assets.asset.title}</h1>
          </div>
          <div>
            {!isPlaying ? (
              <img
                src={playIcon}
                className="soundicon"
                onClick={startSound}
                alt="start-icon"
              />
            ) : (
              <img
                src={pauseIcon}
                className="soundicon"
                onClick={pauseSound}
                alt="pause-button"
              />
            )}
          </div>

          <div className="timeline-outer">
            <input
              type="range"
              min="0"
              max={duration}
              value={seconds}
              className="timeline"
              onChange={handleRangeChange}
            />
          </div>

          <img
            src={navIcon}
            className="icon"
            onClick={navigateToHomepage}
            alt="navigate-back-icon"
          />

          <audio
            controls
            src={assets.asset.sound}
            style={{ display: "none" }}
            ref={audioRef}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Playback;
