import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageContext } from "../context/PageContext";
import configdata from "../config.js";
import ornament from "../assets/icons/ornamens.svg"

const Home = () => {
  const [assets, setassets] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const ctx = useContext(PageContext);

  useEffect(() => {
    ctx.setIsHomepage(true);
  }, [ctx]);

  useEffect(() => {
    setassets(configdata[1]);
  }, []);

  const navigateFromHome = (asset) => {
    if (asset.hasOwnProperty("subpoints")) {
      navigate("/audioselector", { state: asset });
    } else {
      navigate("/playback", {
        state: { asset: asset, path: location.pathname },
      });
    }
  };

  return (
    <div className="home-view">
      {Object.keys(assets).length !== 0 && (
        <>
          <div className="titles">
            {assets.hu && (
              <h1
                className="main-title"
                onClick={() => {
                  navigateFromHome(assets.hu);
                }}
              >
                {assets.hu.title}
              </h1>
            )}
            <img src={ornament} alt="ornamens" className="ornament"></img>
            {assets.hu2 && (
              <h1
                className="main-title"
                onClick={() => {
                  navigateFromHome(assets.hu2);
                }}
              >
                {assets.hu.title}
              </h1>
            )}

            {assets.ro ? (
              <>
                <h1
                  className="main-title"
                  onClick={() => {
                    navigateFromHome(assets.ro);
                  }}
                >
                  {assets.ro.title}
                </h1>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
