import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import navIcon from "../assets/icons/back-button.svg";
import { PageContext } from "../context/PageContext";

const AudioSelector = () => {
  const location = useLocation();
  const data = location.state;
  const subpoints = data && data.subpoints;
  const navigate = useNavigate();
  const ctx = useContext(PageContext);

  useEffect(() => {
    if (ctx.isHomepage === false) {
      navigate("/");
    }
  }, [ctx, navigate]);

  const navigateToHomepage = () => {
    navigate("/");
  };

  const navigateToPlayback = (asset) => {
    navigate("/playback", {
      state: { asset: asset, path: location.pathname, allassets: data },
    });
  };

  return (
    <>
      {data ? (
        <div className="selector-view">
          <div className="titles-holder">
            {subpoints && (
              <ul>
                {subpoints.map((item, index) => {
                  return (
                    <li key={index}>
                      <h1
                        key={index}
                        className="title"
                        onClick={() => navigateToPlayback(item)}
                      >
                        {item.title}
                      </h1>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <img
            src={navIcon}
            className="icon"
            onClick={navigateToHomepage}
            alt="navigate-back"
          ></img>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AudioSelector;
